@import "variables";

p {
    font-size: 18px;
    line-height: 1.65;
}
h1 {
    font-weight: 600;
    font-size: 2.8427em;
    line-height: 1.15;
}
h2 {
    // font-size:3.052em;
    font-size: 1.99em;
    font-weight: 600;
    line-height: 1.25;
}
h3 {
    font-size: 1.414em;
    font-weight: 700;
}
h4 {
    font-size: 1.25em;
    font-weight: 700;
}
h5 {
    font-size: 1.15em;
    font-weight: 700;
}
@media screen and (min-width: 601px) {
    .home .intro {
        h1 {
            max-width: 600px;
        }
        p {
            max-width: 650px;
        }
    }
    #grid {
        h2 {
            max-width: 550px;
            margin: 0 auto;
        }
    }
}
@media screen and (min-width: 1200px) {
    .home .intro {
        h1 {
            max-width: 650px;
        }
        p {
            max-width: 700px;
        }
    }
    #grid {
        h2 {
            max-width: 650px;
            margin: 0 auto;
        }
    }
    h1 {
        font-weight: 600;
        font-size: 3.998em;
        line-height: 1.15;
    }
    h2 {
        // font-size:3.052em;
        font-size: 2.8427em;
        font-weight: 600;
        line-height: 1.15;
    }
    h3 {
        font-size: 1.99em;
        font-weight: 700;
    }
    h4 {
        font-size: 1.414em;
        font-weight: 700;
    }
}
